























































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, PropSync, Prop } from 'vue-property-decorator'
import TariffTabCardDetails from '../_enduser/TariffTabCardDetails.vue'
import DetailsCard from '../../components/relation/DetailsCardOrganisation.vue'
import RelationContactDetailCard from '../../components/relationcontact/RelationContactDetailCard.vue'
import RelationContactApi, {
  RelationContactInterface
} from '@/services/RelationContactApi'
import NewModal from './NewUserOrganisation.vue'
import RelationSelector from '../../components/relationTree/RelationSelector.vue'
import { isRequired, isEmail } from '../../functions/formRules'
import { mapGetters } from 'vuex'

@Component({
  components: {
    TariffTabCardDetails,
    DetailsCard,
    RelationContactDetailCard,
    NewModal,
    RelationSelector
  },
  filters: {
    formatLA(val: string) {
      return val !== undefined && val !== '' ? val : ' - '
    },
    formatEuro(val: any) {
      console.log(val)
      let valRt: any = 0
      if (typeof val === 'number') {
        if (val >= 0) {
          valRt = (val / 1).toFixed(2).replace('.', ',')
        }
        return '€ ' + valRt
      }
      return val
    }
  },
  computed: {
    ...mapGetters({ userDT: 'user/userInfo' })
  }
})
export default class ProfileCard extends Vue {
  @Prop(Array) driverTab: any
  @PropSync('sltDrv', { type: String }) curDriver!: any

  valSynced = false
  loadingDetails = false
  driverID = ''
  relationInfo = {}
  isFetchinData = false
  isNewDriver = false
  driverInfo: any = {}
  isSavingDriver = false
  isSpecifyDriver = false
  payload = {
    description: '',
    relationid: '',
    printednumber: '',
    isPublicCharging: false,
    subscriptiontype: 'SUB_COSTLESS',
    firstname: '',
    lastname: '',
    relationcontactid: '',
    email: '',
    hasportal: false,
    telephone: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    country: ''
  }

  required: Array<any> = [isRequired('This field is required')]

  rulesEmail: Array<any> = [
    isRequired('This field is required'),
    isEmail('Invalid Email address')
  ]

  mounted() {
    this.setDriver(this.curDriver)
  }

  cancel() {
    this.curDriver = ''
    this.valSynced = false
    this.payload = {
      description: '',
      relationid: '',
      printednumber: '',
      isPublicCharging: false,
      subscriptiontype: 'SUB_COSTLESS',
      firstname: '',
      lastname: '',
      relationcontactid: '',
      email: '',
      hasportal: false,
      telephone: '',
      street: '',
      housenumber: '',
      postalcode: '',
      city: '',
      country: ''
    }
    // @ts-ignore
    this.usrDTA = this.userDT
    this.$emit('refresh')
  }

  setDriver(val: any) {
    this.isSpecifyDriver = !this._.isEmpty(val)
    this.driverInfo = this.driverTab.filter(function (elm: any) {
      return elm.id === val
    })
    if (this.driverInfo.length === 1) {
      this.driverInfo = this.driverInfo[0]
      this.payload.firstname = this.driverInfo.firstname
      this.payload.lastname = this.driverInfo.lastname
      this.payload.email = this.driverInfo.email
      this.payload.relationcontactid = this.driverInfo.id
      this.payload.telephone = this.driverInfo.telephone
      this.payload.street = this.driverInfo.address.street
      this.payload.housenumber = this.driverInfo.address.housenumber
      this.payload.postalcode = this.driverInfo.address.postalcode
      this.payload.city = this.driverInfo.address.city
      this.payload.country = this.driverInfo.address.country
    }
  }

  async newDriver() {
    const driverModel: RelationContactInterface = {}
    driverModel.relationid = this.driverInfo.id
    driverModel.firstname = this.payload.firstname
    driverModel.lastname = this.payload.lastname
    driverModel.email = this.payload.email
    driverModel.telephone = this.payload.telephone
    driverModel.street = this.payload.street
    driverModel.postalcode = this.payload.postalcode
    driverModel.city = this.payload.city
    driverModel.country = this.payload.country
    console.log(driverModel)
    try {
      const dt: any = await new RelationContactApi().createUpdate(driverModel)
      this.payload.relationcontactid = dt.data.Id
      this.notification({
        content: 'Driver created',
        color: 'success',
        position: 'right-top'
      })
      this.$emit('reloadDriverTab')
      this.isNewDriver = false
      this.curDriver = dt.data.Id
    } catch (e) {
      console.log(e)
      this.notification({
        content: 'User already exist',
        color: 'error',
        position: 'right-top'
      })
    }
  }

  resetDriver() {
    this.payload.firstname = ''
    this.payload.lastname = ''
    this.payload.email = ''
    this.payload.telephone = ''
    this.payload.street = ''
    this.payload.housenumber = ''
    this.payload.postalcode = ''
    this.payload.city = ''
    this.payload.country = ''
  }

  @Watch('curDriver', { deep: true })
  curDriverOnChanged(val: any) {
    if (this.isNewDriver) {
      this.resetDriver()
    } else {
      this.setDriver(val)
    }
  }
}
