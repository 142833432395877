















































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import SubscriptionApi from '../../services/SubscriptionApi'
import moment from 'moment'
import TariffTabCardDetails from './TariffTabCardDetails.vue'
import Sessions from './Sessions.vue'
import ProfileCardToken from './ProfileCardToken.vue'
import LastTenSessions from '@/components/tokens/LastTenSessions.vue'
import RelationContactApi, { RelationContactInterface } from '@/services/RelationContactApi'
import TokenApi from '@/services/TokenApi'
import SubscriptionTypeApi from '../../services/SubscriptionTypeApi'
import DriverCard from './chargepass/DriverCard.vue'
import SubscriptionCard from './chargepass/SubscriptionCard.vue'
import RelationApi from '@/services/RelationApi'
import { isRequired,isMaxLengthCheck, isEmail, isPhone, isVAT, isTexte, isAddress, isHouseNumber } from '../../functions/formRules'

@Component({
  components: {
    TariffTabCardDetails,
    LastTenSessions,
    Sessions,
    DriverCard,
    SubscriptionCard,
    ProfileCardToken
  },
  filters: {
    formatDateTime (val: string) {
      const b = moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY')
      return b
    },
    formatLA (val: string) {
      return val !== undefined && val !== '' ? val : ' - '
    },
    formatEuro (val: any) {
      console.log(val)
      let valRt: any = 0
      if (typeof val === 'number') {
        if (val >= 0) {
          valRt = (val / 1).toFixed(2).replace('.', ',')
        }
        return '€ ' + valRt
      }
      return val
    },
    toPass (val: any) {
      if (val === '') {
        return 'Non-Blue Corner Pass' // this.t('$vuetify.myTokens.nonBluecorner')
      }
      return val
    }
  },
  computed: {
    ...mapGetters({ userDTA: 'user/userInfo', contractDTA: 'chargepoints/contractDTA' })
  }
})
export default class Subscriptions extends Vue {
  @Prop({ type: String, default: '0' }) readonly id!: string
  isActive= false
  showTariff= false
  isEdit = false
  isFetching = false
  isDialogSubscription = false
  isDialogDriver = false
  isTokenLoading = false
  isEditDescription = false
  isEditInvoiceReference = false
  editDescription = ''
  editInvoiceReference = ''
  errMSGUpdateDesc = ''
  errMSGUpdateInvoiceReference = ''
  isNewDriver = false

  tokensData: any = {
    subscription: {}
  }

  subscriptionsTab: any = []

  isSavingToken = false

  allContact: any = []

  tokenForm: any = [
    {
      printednumber : '',
      isValidToken: false,
      description : '',
      user: {
        relationcontactid: 0,
        relationid: 0,
      },
      subscriptiontypeid: -1,
      isPublic: false,
      currentSub: 0
    }
  ]

  ruleReference: Array<any> = [
    isMaxLengthCheck('Maximum no. of characters allowed is 50', 50)
  ]

  itemsBrdcrmb: any = []

  headersCustom= [
    { text: this.t('$vuetify.myTokens.subTypeKwh'), value: 'per kWh', link: '', isBasic: true },
    { text: this.t('$vuetify.myTokens.custom'), value: 'per kWh', align: 'center', link: '/custom.png', isBasic: true },
    { text: this.t('$vuetify.myTokens.connectionRate'), value: 'per min.', align: 'center', link: '', isBasic: true }
  ]

  subscriptionTab: any= [
    { subscriptionType: this.t('$vuetify.myTokens.annualFee'), starter: '', fan: '€ 59', addict: '€ 169', connectionRate: '' },
    { subscriptionType: this.t('$vuetify.myTokens.normalChargers'), starter: '€ 0.40', fan: '€ 0.35', addict: '€ 0.33', connectionRate: '€ 0.01 *' },
    { subscriptionType: this.t('$vuetify.myTokens.fastCharger'), starter: 0.69, fan: 0.60, addict: 0.56, connectionRate: 0.25 },
    { subscriptionType: ' ', starter: 'choose', fan: 'choose', addict: 'choose', connectionRate: '' }
  ]

  items= [
    {
      text: 'Home',
      disabled: false,
      href: 'breadcrumbs_dashboard'
    },
    {
      text: 'Administration',
      disabled: false,
      href: 'breadcrumbs_link_1'
    },
    {
      text: 'My charge cards',
      disabled: true,
      href: 'breadcrumbs_link_1'
    }
  ]

  headersSubscription= [
    { text: this.t('$vuetify.myTokens.subTypeKwh'), value: 'per kWh', link: '' },
    { text: this.t('$vuetify.myTokens.startKwh'), value: 'per kWh', align: 'center', link: '/starter.png' },
    { text: this.t('$vuetify.myTokens.fanKwh'), value: 'per kWh', align: 'center', link: '/fan.png' },
    { text: this.t('$vuetify.myTokens.addict'), value: 'per kWh', align: 'center', link: '/addict.png' },
    { text: this.t('$vuetify.myTokens.connectionRate'), value: 'per min.', align: 'center', link: '' }
  ]

  sessions = []

  payload = {
    description: '',
    relationid: '',
    printednumber: '',
    isPublicCharging: false,
    subscriptiontype: 'SUB_COSTLESS',
    firstname: '',
    lastname: '',
    relationcontactid: '',
    email: '',
    hasportal: false,
    telephone: '',
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    country: ''
  }

  payloadSubscriptionEdit = {
    printednumber: '',
    subscriptiontypeid: '',
    relationid: '',
    relationcontactid: ''
  }

  updateDriver = {
    relationcontactid: ''
  }

  editSubscriptionPayload = {
    printednumber: '',
    subscriptiontypeid: '',
    relationid: '',
    relationcontactid: ''
  }

  driverInfo: any = {}
  driverTab: any = []
  driverID = ''
  selectedDriver = ''
  curDrv = ''
  driverModel: RelationContactInterface = {}
  loading = false
  isLoadingCard = false
  loadingContact = false

  mounted () {
    this.getDetails()
    this.getSub()
  }

  initBreadCrumb () {
    this.itemsBrdcrmb = [{
      text: this.t('$vuetify.myChargeCards.title'),
      disabled: false,
      href: 'navtokens',
      to: { name: 'navtokens' },
      last: false
    },
    {
      text: this.tokensData.printednumber,
      disabled: true,
      href: 'breadcrumbs_link_1',
      last: true
    }]
  }

  saveDriver () {
    this.saveToken()
  }

  async changeToken () {
  try {
    this.isSavingToken = true
    for (let index = 0; index < this.tokenForm.length; index++) {
      const element: any = this.tokenForm[index];
      console.log(element)
      const linkData = {
        description: element.description,
        printednumber: element.printednumber,
        subscriptiontypeid: element.isPublic ?  element.subscriptiontypeid.Code : 'SUB_COSTLESS',
        relationid: element.user.relationid,
        relationcontactid: element.user.relationcontactid
      }
      const linkRslt: any = await new TokenApi().link(linkData)
      this.cancelDriver()
    }
    this.cancel()
    this.isSavingToken = false
    this.$emit('refresh')
  } catch (e: any) {
    this.isSavingToken = false
    const msg = e.errorcode
    this.notification({ content: msg, timeout: -1, color: 'error' })
    console.log(msg)
    // this.summaryErrMSG = this.t(msg)
  }
}

  async saveToken () {
  try {
    this.isSavingToken = true
    for (let index = 0; index < this.tokenForm.length; index++) {
      const element: any = this.tokenForm[index];
      const linkData = {
        description: element.description,
        printednumber: element.printednumber,
        subscriptiontypeid: element.isPublic ?  element.subscriptiontypeid.Code : 'SUB_COSTLESS',
        relationid: '',
        relationcontactid: ''
      }
      // driver selected
      if (element.user.relationid > 0) {
        linkData.relationid = element.user.relationid
        linkData.relationcontactid = element.user.id
      } else {
        // entity selected
        linkData.relationid = element.user.id
      }
      const linkRslt: any = await new TokenApi().link(linkData)
      this.cancelDriver()
    }
    this.cancel()
    this.isSavingToken = false
    this.$emit('refresh')
  } catch (e: any) {
    this.isSavingToken = false
    const msg = e.errorcode
    this.notification({ content: msg, timeout: -1, color: 'error' })
    console.log(msg)
    // this.summaryErrMSG = this.t(msg)
  }
}

  cancelDescription () {
    this.isEditDescription = false
    this.editDescription = this.tokensData.description
  }

  cancelInvoiceReference () {
    this.isEditInvoiceReference = false
    this.editInvoiceReference = this.tokensData.subscription.subscriptionreference
  }

  cancelDriver () {
    this.isDialogDriver = false
    this.isDialogSubscription = false
    this.tokenForm = [
      {
        printednumber : '',
        isValidToken: false,
        description : '',
        user: {
          relationcontactid: 0,
          relationid: 0,
        },
        subscriptiontypeid: -1,
        isPublic: false,
        currentSub: 0
      }
    ]
    this.getDetails()
  }

  async getSubscription () {
    try {
      const dt = await new SubscriptionTypeApi().getAllSubscriptions() as any
      this.subscriptionsTab = dt.data
    } catch (error) {
      console.log(error)
    }
  }

  async getSub () {
    try {
      const dt = await new SubscriptionTypeApi().getAllSubscriptions() as any
      this.headersCustom = []
      this.headersCustom.push(
        { text: this.t('$vuetify.myTokens.subTypeKwh'), value: 'per kWh', link: '', isBasic: false }
      )
      this.subscriptionTab = []
      const annualFeeTab:any = [this.t('$vuetify.myTokens.annualFee')]
      const normalChargerFeeTab:any = [this.t('$vuetify.myTokens.normalChargers')]
      const fastChargerFeeTab:any = [this.t('$vuetify.myTokens.fastCharger')]
      const tarrifBtn:any = ['']

      for (let index = 0; index < dt.data.length; index++) {
        const element = dt.data[index]
        if (element.Code !== 'SUB_KISS') {
          if(element.Code === 'SUB_STARTER' || element.Code === 'SUB_FAN' || element.Code === 'SUB_ADDICT') {
            this.headersCustom.push(
              { text: element.Description, value: 'per kWh', link: ('/' + element.Code + '.png'), isBasic: true}
            )
          } else {
            this.headersCustom.push(
              { text: element.Description, value: 'per kWh', link: ('/' + element.Code + '.png'), isBasic: false}
            )
          }
          annualFeeTab.push({ text: element.YearlyFee, val: element.Code })
          if (element.Tariffs.length >= 2) {
            normalChargerFeeTab.push({ text: element.Tariffs[0].kwhfee, val: element.Code })
            fastChargerFeeTab.push({ text: element.Tariffs[1].kwhfee, val: element.Code })
          }
          tarrifBtn.push(
            { text: '$vuetify.myTokens.choose', val: element.Code, btn: true }
          )
        }
      }
      this.headersCustom.push(
        { text: this.t('$vuetify.myTokens.connectionRate'), value: 'per kWh', link: '', isBasic: false }
      )
      if(dt.data.length > 0) {
        annualFeeTab.push('€ 0,00')
        normalChargerFeeTab.push(('€ ' + dt.data[0].Tariffs[0].rotationfee))
        fastChargerFeeTab.push(('€ ' + dt.data[0].Tariffs[1].rotationfee))
      } else {
        annualFeeTab.push('€ 0,00')
        normalChargerFeeTab.push('€ 0,01')
        fastChargerFeeTab.push('€ 0,25')
      }
      Vue.set(this.subscriptionTab, 0, annualFeeTab)
      Vue.set(this.subscriptionTab, 1, normalChargerFeeTab)
      Vue.set(this.subscriptionTab, 2, fastChargerFeeTab)
      Vue.set(this.subscriptionTab, 3, tarrifBtn)
    } catch (error) {
      console.log(error)
    }
  }

  async saveDescription () {
    try {
      const payload = {
        id: this.tokensData.id,
        description: this.editDescription,
        subscriptionreference: this.editInvoiceReference
      }
      const dt: any = await new TokenApi().updateTokenData(payload)
      this.isEditDescription = false
      this.editDescription = ''
      this.errMSGUpdateDesc = ''
      this.getDetails()
    } catch (error: any) {
      this.errMSGUpdateDesc = error
    }
  }

  async saveInvoiceReference () {
    try {
      const payload = {
        id: this.tokensData.id,
        description: this.editDescription,
        subscriptionreference: this.editInvoiceReference
      }
      const dt: any = await new TokenApi().updateTokenData(payload)
      this.isEditInvoiceReference = false
      this.editDescription = ''
      this.errMSGUpdateInvoiceReference = ''
      this.getDetails()
    } catch (error: any) {
      this.errMSGUpdateInvoiceReference = error
    }
  }

  async newDriver () {
    this.driverModel.relationid = this.driverID
    this.driverModel.firstname = this.payload.firstname
    this.driverModel.lastname = this.payload.lastname
    this.driverModel.email = this.payload.email
    this.driverModel.telephone = this.payload.telephone
    this.driverModel.street = this.payload.street
    this.driverModel.postalcode = this.payload.postalcode
    this.driverModel.city = this.payload.city
    this.driverModel.country = this.payload.country
    try {
      const dt: any = await new RelationContactApi().createUpdate(this.driverModel)
      this.payload.relationcontactid = dt.data.Id
      this.notification({ content: 'Driver created', color: 'success', position: 'right-top' })
      this.fetchDriverData()
      this.isNewDriver = false
    } catch (e) {
      console.log(e)
      this.notification({ content: 'User already exist', color: 'error', position: 'right-top' })
    }
  }

  async fetchDriverData () {
    const data = await new RelationContactApi().myRelationContacts(this.tokensData.relation.id) as any
    this.driverTab = data.data
    this.resetDriver()
  }

  cancel () {
    this.isDialogSubscription = false
    this.getDetails()
  }

  resetDriver () {
    this.payload.firstname = ''
    this.payload.lastname = ''
    this.payload.email = ''
    this.payload.telephone = ''
    this.payload.street = ''
    this.payload.housenumber = ''
    this.payload.postalcode = ''
    this.payload.city = ''
    this.payload.country = ''
  }

  async getDetails () {
    try {
      this.isFetching = true
      const dt: any = await new SubscriptionApi().details(this.id)
      this.isFetching = false
      this.tokensData = dt.data
      this.editDescription = this.tokensData.description
      this.editInvoiceReference = this.tokensData.subscription.subscriptionreference
      this.initPayload()
      this.isActive = this.tokensData.state === 'ACTIVE' || this.tokensData.state === 'Active'
      const data = await new RelationContactApi().myRelationContacts(this.usrDTA.data.Relation.Id) as any
      this.driverTab = data.data
      this.resetDriver()
      this.curDrv = this.tokensData.relationcontact.id
      this.initBreadCrumb()
      this.tokenForm[0].printednumber = this.tokensData.printednumber
      this.tokenForm[0].description = this.tokensData.description
      this.tokenForm[0].subscriptiontypeid = {
        Id: this.tokensData.subscription.subscriptiontype.id,
        Code: this.tokensData.subscription.subscriptiontype.code,
        Description: this.tokensData.subscription.subscriptiontype.description
      }
      this.tokenForm[0].user.relationid = this.tokensData.relation.id
      this.tokenForm[0].user.relationcontactid = this.tokensData.relationcontact.id
      this.tokenForm[0].isPublic = this.tokensData.subscription.subscriptiontype.code !== 'SUB_COSTLESS' && this.tokensData.subscription.subscriptiontype.code !== 'PRIVATE'
      this.tokenForm[0].currentSub = 0
      this.fetchData()
      this.getSubscription()
    } catch (error) {
      this.isFetching = true
      this.resetDriver()
      console.log(error)
    }
  }

  async fetchData () {
    this.loadingContact = true
    if (this.usrDTA) {
      try {
        this.allContact = []
        // get relation contact
        const data: any = await new RelationContactApi().myRelationContacts(this.usrDTA.data.Relation.Id, false) as any
        this.allContact = data.data
        // get relations
        const dt = await new RelationApi(0).relationTree({}, true, '',  0) as any
        this.allContact = this.allContact.concat(dt.data.relations)
      } catch (error) {
        console.log(error)
      }
    }
    this.loadingContact = false
  }

  get usrDTA (): any {
    // @ts-ignore
    return this.userDTA
  }

  initPayload () {
    this.payload.isPublicCharging = this.tokensData.subscription.subscriptiontype.code !== 'SUB_COSTLESS'
    this.payload.subscriptiontype = this.tokensData.subscription.subscriptiontype.code
  }

  async updateSubscription () {
    this.isDialogSubscription = false
    this.payloadSubscriptionEdit.printednumber = this.tokensData.printednumber
    this.payloadSubscriptionEdit.subscriptiontypeid = this.tokensData.subscription.subscriptiontype.code
    this.payloadSubscriptionEdit.relationid = this.tokensData.relation.id
    this.payloadSubscriptionEdit.relationcontactid = this.curDrv
    if (!this.payload.isPublicCharging) {
      this.payloadSubscriptionEdit.subscriptiontypeid = 'SUB_COSTLESS'
    }
    try {
      this.isTokenLoading = true
      let dt: any = await new SubscriptionApi().changeSubscription(this.payloadSubscriptionEdit)
      dt = await new SubscriptionApi().details(this.id)
      this.tokensData = dt.data
      this.isTokenLoading = false
    } catch (error) {
      console.log(error)
    }
  }

  async editDriver () {
    this.isDialogDriver = false
    this.updateDriver.relationcontactid = this.curDrv
    const idSub = this.tokensData.subscription.id
    const dt = await new SubscriptionApi().changeDriver(idSub, this.updateDriver)
    this.getDetails()
    this.fetchDriverData()
  }

  async deactivateCard () {
    this.isLoadingCard = true
    const dt = await new SubscriptionApi().deactivate(this.tokensData.code)
    const dta: any = await new SubscriptionApi().details(this.id)
    this.tokensData = dta.data
    this.isActive = this.tokensData.state === 'ACTIVE'
    this.isLoadingCard = false
    this.notification({ content: 'Deactivated', color: 'grey', position: 'right-top' })
  }

  async activateCard () {
    this.isLoadingCard = true
    const dt = await new SubscriptionApi().activate(this.tokensData.code)
    const dta: any = await new SubscriptionApi().details(this.id)
    this.tokensData = dta.data
    this.isActive = this.tokensData.state === 'ACTIVE'
    this.isLoadingCard = false
    this.notification({ content: 'Activated', color: 'success', position: 'right-top' })
  }

  @Watch('selectedDriver', { deep: true })
  selectedDriverOnChanged (val: any) {
    this.driverInfo = this.driverTab.filter(function (elm: any) {
      return elm.id === val
    })
    if (this.driverInfo.length === 1) {
      this.driverInfo = this.driverInfo[0]
    }
    this.payload.firstname = this.driverInfo.firstname
    this.payload.lastname = this.driverInfo.lastname
    this.payload.email = this.driverInfo.email
    this.payload.relationcontactid = this.driverInfo.id
    this.payload.telephone = this.driverInfo.telephone
    this.payload.street = this.driverInfo.address.street
    this.payload.housenumber = this.driverInfo.address.housenumber
    this.payload.postalcode = this.driverInfo.address.postalcode
    this.payload.city = this.driverInfo.address.city
    this.payload.country = this.driverInfo.address.country
  }

  @Watch('id')
  idOnChanged (val: any, oldVal: any) {
    this.getDetails()
  }

  @Watch('isDialogSubscription')
  isDialogSubscriptionOnChanged (val: any, oldVal: any) {
    this.initPayload()
  }
}
