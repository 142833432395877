








































import SubscriptionApi from '../../services/SubscriptionApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import SessionDetails from '../../components/sessions/SessionDetails.vue'
import CdrDuration from '../../components/sessions/CdrDuration.vue'
import Time from '@/functions/Time'

@Component({
  components: {
    CdrDuration,
    SessionDetails
  },
  filters: {
    tokWh (val: string | number) {
      if (typeof val === 'string') {
        val = Number(val)
      }
      val = val / 10
      val = Math.round(val)
      return val / 100
    },
    formatEuro (val: any) {
      let valRt: any = 0
      if (val >= 0 && val !== '') {
        // console.log(val)
        valRt = (val / 1).toFixed(2).replace('.', ',')
      }
      return valRt + ' €'
    },
    emptyDt (val: any) {
      if (val === 'undefined €' || val === ' €') {
        val = '0,000 €'
      }
      return val
    },
    toPass (val: any) {
      if (val === '') {
        return 'Non-Blue Corner Pass' // this.t('$vuetify.myTokens.nonBluecorner')
      }
      return val
    }
  }
})
export default class LastTenSessions extends Vue {
  @Prop({ type: String, default: '0' }) readonly token!: string
  pastSessions: Array<any> = []
  loading = false
  dialog = false
  selected = 0

  headers= [
    { text: this.t('$vuetify.myTokens.location'), value: 'Location', sortable: false },
    { text: this.t('$vuetify.myTokens.chargepoint'), value: 'Chargepoint', sortable: false },
    { text: this.t('$vuetify.myTokens.chargepass'), value: 'Charge Pass', sortable: false },
    { text: this.t('$vuetify.myTokens.startStopSession'), value: 'Start/Stop session', sortable: false },
    { text: this.t('$vuetify.myTokens.sessionDuration'), value: 'state', sortable: false }
  ]

  mounted () {
    this.$nextTick(() => {
      if (this.token) {
        this.loadSessies()
      }
    })
  }

  async loadSessies () {
    this.loading = true
    try {
      const dt: any = await new SubscriptionApi().loadSessies(this.token)
      this.pastSessions = dt.data
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }

  sessionClick (event: MouseEvent, sessionId: number | string) {
    // console.log(event, sessionId)
    if (!event.ctrlKey) {
      this.dialog = true
      this.selected = Number(sessionId)
    } else {
      // TODO: fix new page
    }
  }

  calcDiff (start: string, end: string) {
    return Time.durationTime(start, end, 'd[Days] HH[h]mm[m]')
    // return this.$f.DurationTime(start, end, 'mm')
  }

  @Watch('token')
  idOnChanged (val: any, oldVal: any) {
    this.loadSessies()
  }
}
